html, body {
  width: 100%;
  height: 100%;
}

#root {
  height: 100%;
  position: relative;
  padding: 0 6px;

  font-family: 'Roboto', sans-serif;

  .login {
    transform: translateY(50%);
    top: 50%;
  }

  .main {
    margin-top: 80px;
  }

  .input-group-text {
    min-width: 40px !important;
    display: flex;
    justify-content: center;
  }

  [class^="col"] {
    padding-right: 5px;
    padding-left: 5px;
  }

  .switch {
    position: relative;
    width: 40px;
    height: 21px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: var(--success);
      }

      &:checked + .slider:before {
        transform: translateX(19px);
      }

      &:focus + .slider {
        box-shadow: 0 0 1px var(--success);
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--secondary);
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 4px;

      &:before {
        border-radius: 2px;
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
    }
  }

  .table {
    th {
      font-weight: bold;
    }

    td {
      &:first-child {
        width: 10%;
      }

      &:last-child {
        width: 10%;
      }
    }
  }

  .badge {
    padding: 8px
  }

  .react-datepicker__header {
    background: var(--success);


    [class^="react-datepicker"] {
      color: #fff !important;

      border-right-color: #fff !important;
      border-left-color: #fff !important;
    }
  }

  .react-datepicker__day--selected {
    background: var(--danger);
  }

  .input-group-date {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    .react-datepicker {
      border: none;
      box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, .1);
    }

    .react-datepicker__navigation {
      &.react-datepicker__navigation--next {
        border-left-color: #fff;
      }

      &.react-datepicker__navigation--previous {
        border-right-color: #fff;
      }
    }

    .react-datepicker__triangle {
      border-bottom-color: var(--success);
    }

    .datePickerWrapper,
    .datePickerWrapper > div.react-datepicker-wrapper,
    .datePickerWrapper > div > div.react-datepicker__input-container
    .datePickerWrapper > div > div.react-datepicker__input-container input {
      width: 100%;
    }
  }

  .input-group-password {
    position: relative;

    .show-pass-toggle {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: rgba(0, 0, 0, .25);

      &.active {
        color: var(--success)
      }
    }
  }

  .logo {
    color: #fff;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .messages-alert {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 15px;
    min-width: 200px;
    max-width: 80%;
    z-index: 2000;
  }

  .modal {
    background: rgba(0, 0, 0, .9);
  }

  .overview {
    margin-left: -5px;
    margin-right: -5px;

    h5 {
      margin-bottom: 0;
      text-align: center;
    }
  }

  .tip {
    position: relative;

    .tip-text {
      visibility: hidden;
      width: 120px;
      padding: 5px 15px;
      background-color: var(--dark);
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;

      position: absolute;
      z-index: 1;
    }

    &:hover {
      .tip-text {
        visibility: visible;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
  background: var(--dark)
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--dark);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--success);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--success);
}

.user-placeholder {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: inline-block;
  color: #74788d;
  text-align: center;
  border: 1px solid #74788d;
  line-height: 29px;
}
